<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<script setup lang="ts">
import { useAttrs, type PropType, ref } from "vue";

const props = defineProps({
  errors: {
    type: Array as PropType<string[]>,
    required: false,
  },
  modelValue: {
    type: String as PropType<string>,
    required: false,
  },
  type: {
    type: String as PropType<string>,
    default: "text",
  },
  label: {
    type: String as PropType<string>,
    required: false,
  },
});

const emit = defineEmits<{
  (e: "update:modelValue", value: string): void;
}>();

const { class: wrapperClass, inputClass, ...attrs } = useAttrs();

// Add reference to the input element
const inputElement = ref<HTMLInputElement | null>(null);

// Expose focus method to parent components
defineExpose({
  focus: () => {
    inputElement.value?.focus();
  }
});
</script>

<template>
  <div :class="wrapperClass">
    <label class="grow block">
      <slot name="label"></slot>
      <div
        v-if="label && !$slots.label"
        class="text-base text-[#3F4A55] font-medium mb-2 inline-block"
      >
        {{ label }}
      </div>
      <div class="relative">
        <input
          ref="inputElement"
          :type="type"
          :value="modelValue"
          v-bind="attrs"
          @input="
            emit(
              'update:modelValue',
              ($event.target! as HTMLInputElement).value
            )
          "
          :class="[
            errors ? 'input--error' : 'border-[#EAF0F5]',
            'rounded-xl bg-white text-base text-[#3F4A55] placeholder:text-[#BBC4CD] placeholder:font-normal border-2 px-5 py-3 w-full focus:border-[#6212E5] outline-none',
          ]"
        />
      </div>
    </label>
    <ul v-show="!!errors" class="text-[#E31266] mt-2">
      <li v-for="error in errors" :key="error" class="flex">{{ error }}</li>
    </ul>
  </div>
</template>
