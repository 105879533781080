import { usePage } from '@inertiajs/vue3';
import { jsPDF } from "jspdf";

export function deepen(obj) {
    const result = {};
    for (const objectPath in obj) {
        const parts = objectPath.split('.');
        let target = result;
        while (parts.length > 1) {
            const part = parts.shift();
            target = target[part] = target[part] || {};
        }
        target[parts[0]] = obj[objectPath];
    }
    return result;
}

/**
 * Convert every property of given object into camelCase
 * @param {Object} obj
 * @returns {Object}
 */
export function camelCaseProps(obj) {
    const camelCaseObj = {};

    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const camelCaseKey = key.replace(/_([a-z])/g, (g) => g[1].toUpperCase());
            camelCaseObj[camelCaseKey] = obj[key];
        }
    }

    return camelCaseObj;
}

/**
 * Create new object having only specified properties
 * @param {Object} obj 
 * @param {Array<string>} props list of properties to pick from object
 * @returns {Object} object having only the specified properties
 */
export function pickProps(obj, props) {
    const newObj = {};
    props.forEach(prop => {
        if (obj.hasOwnProperty(prop)) {
            newObj[prop] = obj[prop];
        }
    });

    return newObj;
}

export function capitalize(name) {
    return name
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
};

export function pluralize(word, count) {
    return new Intl.PluralRules('en-US', { type: 'cardinal' }).select(count) === 'one' ? word : word + 's';
}

export function copyToClipboard(text) {
    navigator.clipboard.writeText(text);
}

/**
 * 
 * @param {RawMessage} message Message as it comes from backend 
 * @return {Message}
 */
export function formatMessage(message) {
    const m = camelCaseProps(message);
    return {
        id: m.id,
        content: m.content,
        rating: m.rating?.value,
        author: m.author,
        createdAt: new Date(m.createdAt),
        threadId: m.threadId,
        thread: m.thread
    }
}

export async function trimAudioBlob(audioChunks, startTime) {
    // Combine all audio chunks into a single Blob
    const fullAudio = new Blob(audioChunks, { type: 'audio/webm' });

    // Create an AudioContext
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();

    // Convert the Blob to an ArrayBuffer
    const arrayBuffer = await fullAudio.arrayBuffer();

    // Decode the audio data
    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

    // Calculate the start position in samples
    const sampleRate = audioBuffer.sampleRate;
    const startSample = Math.floor(startTime * sampleRate);

    // Create a new AudioBuffer for the trimmed audio
    const trimmedBuffer = audioContext.createBuffer(
        audioBuffer.numberOfChannels,
        audioBuffer.length - startSample,
        sampleRate
    );

    // Copy the trimmed portion of the audio data
    for (let channel = 0; channel < audioBuffer.numberOfChannels; channel++) {
        const channelData = audioBuffer.getChannelData(channel);
        trimmedBuffer.copyToChannel(channelData.subarray(startSample), channel);
    }

    // Convert the trimmed AudioBuffer to a WAV Blob
    const wavBlob = audioBufferToWav(trimmedBuffer);

    return wavBlob;
}

// Helper function to convert AudioBuffer to WAV
function audioBufferToWav(buffer) {
    const numOfChan = buffer.numberOfChannels;
    const length = buffer.length * numOfChan * 2;
    const audioData = new ArrayBuffer(44 + length);
    const view = new DataView(audioData);
    const channels = [];
    let sample, offset = 0;

    // Write WAV header
    writeString(view, 0, 'RIFF');
    view.setUint32(4, 36 + length, true);
    writeString(view, 8, 'WAVE');
    writeString(view, 12, 'fmt ');
    view.setUint32(16, 16, true);
    view.setUint16(20, 1, true);
    view.setUint16(22, numOfChan, true);
    view.setUint32(24, buffer.sampleRate, true);
    view.setUint32(28, buffer.sampleRate * 2 * numOfChan, true);
    view.setUint16(32, numOfChan * 2, true);
    view.setUint16(34, 16, true);
    writeString(view, 36, 'data');
    view.setUint32(40, length, true);

    // Write interleaved audio data
    for (let i = 0; i < buffer.numberOfChannels; i++)
        channels.push(buffer.getChannelData(i));

    offset = 44;
    for (let i = 0; i < buffer.length; i++) {
        for (let channel = 0; channel < numOfChan; channel++) {
            sample = Math.max(-1, Math.min(1, channels[channel][i]));
            sample = (0.5 + sample < 0 ? sample * 32768 : sample * 32767) | 0;
            view.setInt16(offset, sample, true);
            offset += 2;
        }
    }

    return new Blob([audioData], { type: 'audio/wav' });
}

// Helper function to write a string to a DataView
function writeString(view, offset, string) {
    for (let i = 0; i < string.length; i++) {
        view.setUint8(offset + i, string.charCodeAt(i));
    }
}

export function goBack()
{
    const page = usePage();

    if (page.props.redirect_back_url) {
            window.location.href = page.props.redirect_back_url;
            return;
        }
    history.length <= 2 ? window.location.href = route('landingpage'): history.back(); // tab open and page load already makes history.length 2
}

export function can(user, ability, model = null) {
    // TODO: implement other way
    if(ability == 'duplicate'){ 
            if (user.isDemoUser) return false;
            return model.isDuplicatable || user.id === model.author_id;
    }    
    return !!user?.allPermissions?.some(permission => permission.toLocaleLowerCase() === ability.toLowerCase());
}

export function exportChatToClipboard(messages) {
    const chat = messages.map(message => `${message.author.name}: ${message.content}`).join('\n');
    navigator.clipboard.writeText(chat);
}

export async function exportChatToPdf(messages, opts) {
    const { title, score } = opts;

    const pdf = new jsPDF({
        putOnlyUsedFonts: true,
        compress: true,
        unit: 'mm',
        format: 'a4'
    });
    const margin = 10;
    const bottomMargin = 20; // New bottom margin
    let yPosition = margin;

    // Add Unicode font support
    pdf.addFont('https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf', 'Roboto', 'normal');
    pdf.setFont('Roboto');

    const loadImage = (url) => {
        return new Promise((resolve) => {
            const img = new Image();
            img.crossOrigin = 'Anonymous';
            img.onload = () => {
                // Create a canvas to handle transparent backgrounds
                const canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext('2d');
                
                // Fill the background with white
                ctx.fillStyle = 'white';
                ctx.fillRect(0, 0, canvas.width, canvas.height);
                
                // Draw the image on top
                ctx.drawImage(img, 0, 0);
                
                resolve(canvas.toDataURL('image/jpeg'));
            };
            img.onerror = () => resolve(null);
            img.src = url;
        });
    };

    const svgToBase64 = (svgString) => {
        return `data:image/svg+xml;base64,${btoa(svgString)}`;
    }

    const addScoreToPdf = async () => {
        if (score !== undefined && score !== null) {
            const [starSolidImage, starOutlineImage] = await Promise.all([
                loadImage(svgToBase64(`
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFCE21" class="size-6">
                        <path fill-rule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z" clip-rule="evenodd" />
                    </svg>
                `)),
                loadImage(svgToBase64(`
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#FFCE21" class="size-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z" />
                    </svg>
                `))
            ]);
            
            if (starSolidImage && starOutlineImage) {
                const starSize = 8; // Adjust size as needed
                const totalStarWidth = 3 * (starSize + 2) - 2; // 3 stars with 2mm gap, minus last gap
                const startX = (pdf.internal.pageSize.width - totalStarWidth) / 2;
                for (let i = 0; i < 3; i++) {
                    const starImage = i < score ? starSolidImage : starOutlineImage;
                    pdf.addImage(starImage, 'PNG', startX + (i * (starSize + 2)), yPosition, starSize, starSize);
                }
                yPosition += starSize + 5; // Add some space after the stars
            }
        }
    }

    const addHeaderToPdf = async () => {
        pdf.setFontSize(18);
        const headerText = opts.title;
        const textWidth = pdf.getStringUnitWidth(headerText) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
        const textOffset = (pdf.internal.pageSize.width - textWidth) / 2;
        pdf.text(headerText, textOffset, yPosition + 10);
        yPosition += 20; // Add margin under the header

        await addScoreToPdf();

        // Add date
        pdf.setFontSize(12);
        const currentDate = new Date().toLocaleDateString();
        pdf.text(`Date: ${currentDate}`, margin, yPosition);
        yPosition += 10; // Add margin under the date
    }

    const addMessageToPdf = async (message) => {
        const avatarSize = 10;
        const textMargin = margin + avatarSize + 5;
        const lineHeight = 7;

        try {
            const avatarDataUrl = message.author.avatar ? await loadImage(message.author.avatar) : null;
            
            pdf.setFontSize(12);
            const nameHeight = 5;
            pdf.setFontSize(10);
            const contentLines = pdf.splitTextToSize(message.content, pdf.internal.pageSize.width - textMargin - margin);
            const messageHeight = Math.max(avatarSize, contentLines.length * lineHeight) + nameHeight;

            // Check if the message fits on the current page
            if (yPosition + messageHeight > pdf.internal.pageSize.height - bottomMargin) {
                pdf.addPage();
                yPosition = margin;
            }

            if (avatarDataUrl) {
                pdf.addImage(avatarDataUrl, 'JPEG', margin, yPosition, avatarSize, avatarSize);
            }

            pdf.setFontSize(12);
            pdf.text(message.author.name, textMargin, yPosition + 5);

            pdf.setFontSize(10);
            pdf.text(contentLines, textMargin, yPosition + avatarSize + 2);

            yPosition += messageHeight + 5;
        } catch (error) {
            console.error('Failed to add message to PDF:', error);
        }
    };

    await addHeaderToPdf();

    for (const message of messages) {
        await addMessageToPdf(message);
    }

    pdf.save(`${title}-${Date.now()}.pdf`);
}

export function secondsToHms(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    
    if (hours === 0) {
        return `${minutes} minutes`;
    }
    
    return `${hours} hours ${minutes} minutes`;
}

export function getDaysUntilNextMonth() {
    const currentDate = new Date();
    
    // Create date for 1st of next month
    const nextMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
    
    // Calculate difference in days
    const diffTime = nextMonth - currentDate;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    
    return diffDays;
}

export function getIosVersion() {
    // Check if running on iOS
    if (!/iPad|iPhone/.test(navigator.userAgent)) {
        return null;
    }

    // Extract iOS version from user agent
    const matches = navigator.userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/);
    
    if (!matches) {
        return null;
    }

    // Convert version parts to numbers and join with dots
    const version = [
        parseInt(matches[1], 10),
        parseInt(matches[2], 10),
        parseInt(matches[3] || 0, 10)
    ].join('.');

    return version;
}

/**
 * Create and download wav file from audio array
 * For example during testing
 * @param {Int16Array} audio 
 * @param {number} sampleRate 
 */
export function createAndDownloadWavFile(audio, sampleRate = 24000) {
    const packer = new WavPacker();

    const float32Array = new Float32Array(audio.length);
    for (let i = 0; i < audio.length; i++) {
        float32Array[i] = audio[i] / 0x8000;
    }
    const audioData = {
        bitsPerSample: 16,
        channels: [audio],
        data: audio
    };

    const wavResult = packer.pack(sampleRate, audioData);
    const url = URL.createObjectURL(wavResult.blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `speech-${new Date().toISOString()}.wav`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
}
